const ResumeSkills = (props) => {
	const resumeInfo = props.resumeInfo;
	
	return (
		<section className="sectionPod resumeSkills">
			<h3 className="title">Skills</h3>
		    <div className="inlineList">
	    		{resumeInfo && resumeInfo.resume.skills.map((skillGroup) => (
    		    	<div className="listBlock" key={skillGroup.id}>
    	    			<h3 className="listTitle">{ skillGroup.title }</h3>
			    		<ul className="listList">
				    		{skillGroup && skillGroup.skills.map((skill) => (
				    			<li key={skill.id}>
				    				{ skill.title }
				    			</li>
		    				))}
			    		</ul>
    	    		</div>
	    		))}
		    	<div className="listFooter"></div>
    		</div>
		</section>
	);
}
export default ResumeSkills;