const ResumeJobs = (props) => {
	const resumeInfo = props.resumeInfo;
	return (
		<section className="sectionPod resumeJobs">
			<h3 className="title">Jobs</h3>
			<div className="jobsList">
	    		{resumeInfo && resumeInfo.resume.jobs.map((job) => (
			    	<div className="jobPod" key={job.id}>
		    			<h4 className="jobTitle">{ job.title }</h4>
		    			<h5 className="jobCompany">{ job.company }</h5>
		    			<p className="jobDates">{ job.start } - { job.end || 'Present'}</p>
		    			<p className="jobDescription">{ job.description }</p>
		    		</div>
	    		))}
    		</div>
		</section>
	);
}
export default ResumeJobs;