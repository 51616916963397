import ApiTools from './common/ApiTools.js';
import EditResume from './quilly/EditResume';
import DeleteResume from './quilly/DeleteResume';
import CreateResume from './quilly/CreateResume';
import { useState, useEffect } from 'react';
import LoadingBlock from './common/LoadingBlock';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

const Quilly = () => {
	
	const [applications, setApplications] = useState(null);
	const API = new ApiTools(process.env.REACT_APP_API_URL);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	
	const refreshList = () => {
		setIsLoading(true);
		const results = API.get('applications', setApplications, () =>{ setIsLoading(false); });
		if(results.error) {
			setError(results.msg);
		}
		API.token("nothing");
		console.log(API.token());
	};
	
	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		refreshList();
	},[]);
	
	return (
		<Router>
			<div>
			  	<section className="pageBanner">
			  		<LoadingBlock isLoading={isLoading} error={error} />
				  	<div className="pageBannerBG">
				  		<img src="./media/images/home.png" role="presentation" alt=""></img>
			  		</div>
					<h2 className="bannerTitle" id="watermark">
						Eldon Fox, Front-End Developer, Designer, and Watercolor Artist
					</h2>
					<img id="printWatermark" src="./media/images/watermark.png" role="presentation" alt=""></img>
				</section>
				<div className="sectionSet">
					<section className="sectionPod">
						<h3 className="title">Quilly</h3>
						<div className="actionBar">
		    				<Link className="button" to={`/quilly/CreateResume`}>Create Resume</Link>
		    				<button onClick={refreshList}>Refresh</button>
		    			</div>
						<table className="resumeList">
							<thead>
								<tr>
									<th>Submission Date</th>
									<th>Company</th>
									<th>Job Title</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
				    		{applications && applications.map((application) => (
						    	<tr key={application.id}>
					    			<td className="jobDate">{ application.submission_date }</td>
					    			<td className="jobCompany">{ application.organization }</td>
					    			<td className="jobTitle">{ application.job_title }</td>
					    			<td className="actionCell">
						    			<ul className="buttonList">
						    				<li className="item">
							    				<Link className='button' to={`/quilly/EditResume/${application.id}`}>Edit</Link>
							    			</li>
							    			<li className="item">
							    			{application.protected === true ? 
						    					(<Link className='button disabled' to={`#`} >Delete</Link>) : 
							    				(<Link className='button' to={`/quilly/DeleteResume/${application.id}`} >Delete</Link>)
						    				}
							    				
							    			</li>
							    			<li className="item">
							    				<Link className='button' to={`/${application.id}`} target="_blank">URL</Link>
							    			</li>
						    			</ul>
					    			</td>
					    		</tr>
				    		))}
				    		</tbody>
			    		</table>
		    		</section>
					<section className="sectionPod">
						<h3 className="title">Resume Details</h3>
						<Switch>
						<Route exact path="/Quilly/CreateResume/">
							<CreateResume refreshList={ refreshList } />
						</Route>
						<Route path="/Quilly/EditResume/:id" >
							<EditResume refreshList={ refreshList } API={ API }/>
						</Route>
						<Route path="/Quilly/DeleteResume/:id">
							<DeleteResume refreshList={ refreshList } />
						</Route>
						</Switch>
		    		</section>
	    		</div>
			</div>
		</Router>
	);
}
export default Quilly;