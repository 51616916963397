const Header = () => {
	return (
		<header className="appHeader">
			<a id="appLogo" href="/">
				<img src="./media/images/logo.png" alt="Eldon Fox LLC" className="printContent" width="167px" height="36px"/>
			</a>
				{/*
			<nav className="navigation" id="mainMenu">
				<ul id="mainMenuList">
					<li className="navItem">
						<a href="">Link</a>
					</li>
				</ul>
			</nav>
			*/}
		</header>
	);
}

export default Header;