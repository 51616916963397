import { useState, useEffect } from 'react';
const useFetch = (url, localStore) => {

	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const abortControl = new AbortController();

		function loadData(data) {
			setData(data);
			if(localStore !== undefined) {
				localStorage.setItem(localStore, JSON.stringify(data));
			}
			setIsLoading(false);
			setError(null);
		}
		
		fetch(url, { signal: abortControl.signal })
		.then(res => {
			if(!res.ok) {
				throw Error("Data Unavailable. " + res.status + ": " + res.statusText);
			}
			return res.json();
		})
		.then(data => {
			loadData(data);
		})
		.catch(error => {
			setIsLoading(false);
			setError(error.message);
		})

		return () => abortControl.abort();
	},[url, localStore]);
	
	return { data, isLoading, error };
}
export default useFetch;