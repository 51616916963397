const ResumeReferences = (props) => {
	const resumeInfo = props.resumeInfo;

	return(
		<section className="sectionPod resumeReferences">
			<h3 className="title">References</h3>
			<div className="jobsList">
				{(resumeInfo && resumeInfo.references !== false && resumeInfo.references.map((ref) => (
					<p key={ ref.email } className="jobPod">{ ref.name } ({ ref.title || 'Unavailable' }, { ref.organization || 'Unavailable' })
						<dl>
							<dt>Email:</dt> <dd>{ (ref.email !== '' ? <a href={"mailto:" + ref.email}>{ref.email}</a> : 'Unavailable') }</dd>
							<dt>Phone:</dt> <dd>{ (ref.phone !== '' ? <a href={"tel:" + ref.phone}>{ref.phone}</a> : 'Unavailable') }</dd>
						</dl>
					</p>
				))) || <p>References are available upon request.</p>}
			</div>
		</section>
	);
}
export default ResumeReferences;