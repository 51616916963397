
import { useParams, Link, useHistory } from "react-router-dom";

const DeleteResume = (props) => {
	const { id } = useParams();
	let history = useHistory();
	const refreshList = props.refreshList;
	
	const handleDelete = () => {
		
		fetch(process.env.REACT_APP_API_URL + 'applications?id=' +  id , {
			method: 'DELETE',
			headers: { "Content-Type": "application/json" }
		})
		.then(res => {
			console.log('Application Deleted.');
			console.log(res);
			refreshList();
			history.push('/quilly');
		})
		.catch(error => {
			console.log(error.message);
		})
	}
	
	//handleDelete();
	
	return (
		<div>
			<p className="error">ARE YOU SURE!?</p>
			<Link className="button" to={`#`} onClick={()=>{handleDelete()}}>Burn it all!</Link> | <Link className="button" to={`/quilly`}>NO!</Link>
		</div>
	);
}
export default DeleteResume;