
const ResumeAbout = () => {
	return (
		<section className="sectionPod resumeSkills">
			<h3 className="title">About This App</h3>
			<dl className="sectionCallout">
				<dt>Version:</dt> <dd>{process.env.REACT_APP_VERSION_NUMBER}</dd>
				<dt>Released:</dt> <dd>{process.env.REACT_APP_RELEASE_DATE}</dd>
			</dl>
			<p>
				This resume is a progressive web app, written in React with a Rest API powered by a PHP/MySQL back-end.  If you are reading a paper version of this resume, then you already know that my CSS skills are above average. If you are reading this on a computer, take a moment and print the page, I’ll wait ... Provided I haven’t done something terribly wrong, then you’ll have been treated to a nicely formatted document!
			</p>
			<p>
				I would not be so bold as to call this a proper app and the API is rudimentary.  In my defense, I only started properly learning React the first part of August and I’m still wrapping my head around that and ES6.  That said, it has been an excellent learning opportunity.
			</p>
			
		</section>
		);
}
export default ResumeAbout;