import Header from './Header';
import Resume from './Resume';
import Footer from './Footer';
import Quilly from './Quilly';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {
	const lastVisited = new Date(parseInt(localStorage.getItem('lastVisited')));
	console.log(lastVisited);
	window.onbeforeunload = () => {
		localStorage.setItem('lastVisited', Date.now());
	}
	
	// Check that service workers are supported
	if ('serviceWorker' in navigator) {
	  // Use the window load event to keep the page load performant
	  window.addEventListener('load', () => {
	    navigator.serviceWorker.register('/service-worker.js')
	    	.then(registrationObj => {
    	})
    	.catch(error => {
    	});
	  });
	  navigator.serviceWorker.ready
	  .then(reqistrationObj => {
		  reqistrationObj.update()});
	}
  return (
	<Router>
	    <div className="App">
	    	<Header/>
	    	<Switch>
				<Route exact path="/">
					<Resume/>
				</Route>
				<Route path="/quilly" render={props => <Quilly {...props} />} />
				<Route exact path="/:id" render={props => <Resume {...props} />} />
	    	</Switch>
	    	<Footer/>
	    </div>
	</Router>
  );
}

export default App;
