const Footer = () => {
	return (
		<footer>
		<cite className="cite" title="Contact Eldon Today!">
			<span className="sentence">Like what you see? </span>
			<span className="sentence">Have a Question? </span>
			<span className="sentence">Contact me! </span>
			<dl>
				<dt>Mobile:</dt><dd><a href="tel:+5415136624">541-513-6624</a></dd>
				<dt>Email:</dt><dd><a href="mailto:eldon@eldonfox.com?subject=Regarding Your Resume">eldon@eldonfox.com</a></dd>
			</dl>
		</cite>
		</footer>
	);
}
export default Footer;