import { useState, useEffect } from 'react';
import parse from 'html-react-parser';

const ResumeOverview = (props) => {
	const resumeInfo = props.resumeInfo;
	const [ coverLetter, setCoverLetter] = useState(null);
	const [ letterTitle, setLetterTitle] = useState(null);
	
	useEffect(() => {
        if(resumeInfo && typeof resumeInfo.application[0] !== 'undefined') {
        	setLetterTitle(resumeInfo.application[0].organization + ', ' + resumeInfo.application[0].job_title);
        	setCoverLetter(parse(resumeInfo.application[0].coverLetter));
        } else {
        	setLetterTitle('Overview');
        	setCoverLetter(
        		<div>
        			<p>
        				When it comes to application development, my strengths are definitely geared to the front-end.  I have a passion for the user experience.  To me, a bad UI is an affront to the user.  It is inexcusable to make the user work to understand how to use an application.  An application should be a bridge to a solution rather than a barrier.  When it comes to programming, the end user is the person who matters to me the most.  I can’t stand the thought of letting them down!
        			</p>
        			<p>
        			    From planning to deployment and maintenance, I can help you at every step of the project life cycle. <a href="https://www.eldonfox.com/contact/" target="new">Contact me today</a> and lets get started!
        			</p>
        	    </div>);
        }
	},[resumeInfo]);
	    
	return (
		<section className="sectionPod resumeOverview">
			<h3 className="title">{ letterTitle }</h3>
			{ coverLetter }
		</section>
	);
}
export default ResumeOverview;