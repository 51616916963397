import { useState } from 'react';
import { useHistory } from "react-router-dom";

const CreateResume = (props) => {
	
	const [ date, setDate ] = useState('');
	const [ org, setOrg ] = useState('');
	const [ title, setTitle ] = useState('');
	const [ letter, setLetter ] = useState('');
	const history = useHistory();
	const refreshList = props.refreshList;
	
	const handleSubmit = (e) => {
		e.preventDefault();
		const application = {date, org, title, letter, protected:0};
		console.log(JSON.stringify(application));
		
		fetch(process.env.REACT_APP_API_URL + 'applications', {
			method: 'POST',
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(application)
		}).then(res => {
			if(!res.ok) {
				throw Error("Data Unavailable. " + res.status + ": " + res.statusText);
			}
			console.log('new application added');
			console.log(res);
			refreshList();
			history.push('/quilly');
		})
		.catch(error => {
			console.log(error.message);
		})
	}

	const handleCancel = (e) => {
		e.preventDefault();
		history.push('/quilly');
	}
	
	return(
		<div>
			<form onSubmit={handleSubmit}>
				<label>Date</label>
				<input 
					type="text"
					required
					value={date}
					onChange={(e) => setDate(e.target.value)}
				/>
				<label>Organization</label>
				<input 
					type="text"
					required
					value={org}
					onChange={(e) => setOrg(e.target.value)}
				/>
				<label>Job Title</label>
				<input 
					type="text"
					required
					value={title}
					onChange={(e) => setTitle(e.target.value)}
				/>
				<div className="editor">
					<label className="editorLabel">Cover Letter</label>
					<textarea
						required
						className="docBody"
						value={letter}
						onChange={(e) => setLetter(e.target.value)}
					></textarea>
				</div>
				<div className="actionBar">
					<button>Save</button>
					<button type="button" onClick={handleCancel}>Cancel</button>
				</div>
			</form>
		</div>
	);
}
export default CreateResume;