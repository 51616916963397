const ResumeRecommendations = (props) => {
	const resumeInfo = props.resumeInfo;
	
	return(
		<section className="sectionPod resumeRecommendations">
			<h3 className="title">Recommendations</h3>
		    <div className="quoteSetWrapper">
		    	<div className="quoteSet">
		    	{resumeInfo && resumeInfo.resume.recommendations.map((rec) => (
	    			<div className="quote" key={rec.id}>
		    			<p>
		    				"{ rec.recommendation }"
	    				</p>
	    				<p>
		    				<em className="quoteSource">-<strong className="name">{ rec.author }</strong>, { rec.auth_title }</em>
		    				<em className="quoteSource">{ rec.auth_org}</em>
		    			</p>
		    		</div>
    			))}
	    		</div>
		    	<div className="setFooter"></div>
		    </div>
		</section>
	);
}
export default ResumeRecommendations;