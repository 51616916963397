import { useParams } from 'react-router-dom';
import useFetch from './useFetch';
import ResumeOverview from './resume/ResumeOverview';
import ResumeAbout from './resume/ResumeAbout';
import ResumeSkills from './resume/ResumeSkills';
import ResumeRecommendations from './resume/ResumeRecommendations';
import ResumeReferences from './resume/ResumeReferences';
import ResumeJobs from './resume/ResumeJobs';
import ResumeEdu from './resume/ResumeEdu';
//import TestClass from './TestClass';

const Resume = () => {
	const { id } = useParams();
	const { data:resumeInfo } = useFetch(process.env.REACT_APP_API_URL + 'application?id=' +  id, 'application');
	
	return (
		<div>
		  	<section className="pageBanner">
			  	<div className="pageBannerBG">
			  		<img src="./media/images/home.png" role="presentation" alt=""></img>
		  		</div>
				<h2 className="bannerTitle" id="watermark">
					Eldon Fox, Front-End Developer, Designer, and Watercolor Artist
				</h2>
				<img id="printWatermark" src="./media/images/watermark.png" role="presentation" alt="" height="185px" width="272px" ></img>
			</section>
			<div className="sectionSet">
				<div className="subPod">
				{/*
					<TestClass test="TestThis" url="http://www.eldonfox.com" />
					*/}
					<ResumeOverview resumeInfo={ resumeInfo } />
					<ResumeAbout />
					<ResumeSkills  resumeInfo={ resumeInfo } />
				</div>
				<div className="subPod">
					<ResumeReferences  resumeInfo={ resumeInfo } />
					<ResumeRecommendations  resumeInfo={ resumeInfo } />
				</div>
				<div className="subPod">
					<ResumeJobs  resumeInfo={ resumeInfo } />
					<ResumeEdu />
				</div>
			</div>
		</div>
	);
}
export default Resume;