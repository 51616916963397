class ApiTools 
{
	constructor(apiURL) {
		this.apiURL = apiURL;
	}
	get(resource, setData, callback=null) {
		let result = {error:false, msg:'Update Successful.'};
		fetch(this.apiURL + resource)
		.then(res => {
			if(!res.ok) {
				throw Error("Data Unavailable. " + res.status + ": " + res.statusText);
			}
			return res.json();
		})
		.then(data => {
			setData(data);
			if(callback !== null) {
				callback();
			}
		})
		.catch(error => {
			result.error = true;
			result.msg = error.message;
		});
		return result;
	}
	
	put(resource, body, callback) {
		let result = {error:false, msg:'Update Successful.'};
		fetch(this.apiURL + resource, {
			method: 'PUT',
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(body)
		}).then(res => {
			callback();
			//refreshList();
			//history.push('/quilly');
		})
		.catch(error => {
			result.error = true;
			result.msg = error.message;
		})
		return result;
	}
	
	token(token=null) {
		//alert(Date(Date.now()*60000).toString());
		if(token === null) { // get token
			//return document.cookie;
			
			var match = document.cookie.match(new RegExp('(^| )token=([^;]+)'));
			if (match) {
				return match[2];
			}
		} else { // set token
			//let expDate = new Date(Date.now() + (10*60000));
			//document.cookie = "token=" + token + "; expires=" + expDate.toString() + ";";
			let now = new Date();
			let time = now.getTime();
			let expireTime = time + 1000*36000;
			now.setTime(expireTime);
			document.cookie = 'token='+token+';expires='+now.toUTCString()+';path=/';
			//document.cookie = "token=" + token;
		}
	}
}
export default ApiTools;