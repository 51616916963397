const ResumeEdu = () => {
	return(
		<section className="sectionPod resumeSkills">
			<h3 className="title">Education</h3>
			<div className="jobsList">
				<div className="jobPod">
					<h4 className="jobTitle">AA in Art (Painting)</h4>
					<h5 className="jobCompany">DeAnza College</h5>
					<p className="jobDates">1996 - 1998</p>
					<p className="jobDescription"></p>
				</div>
				<div className="jobPod">
					<h4 className="jobTitle">AS in Computer Science (Programming)</h4>
					<h5 className="jobCompany">College of the Redwoods</h5>
					<p className="jobDates">2001 - 2003</p>
					<p className="jobDescription"></p>
				</div>
				<div className="jobPod">
					<h4 className="jobTitle">Private Pilot Certificate</h4>
					<h5 className="jobCompany">Take Wing</h5>
					<p className="jobDates">2013 - 2015</p>
					<p className="jobDescription"></p>
				</div>
			</div>
		</section>
	);
}
export default ResumeEdu;